<template>
  <div >
       <!-- 创建 -->
    <el-form ref="form" :inline="true" >
        <el-form-item label="">
            <el-input prefix-icon="el-icon-search" v-model="req.keywords" placeholder="请输入关键词搜索"></el-input>
        </el-form-item>
        <el-form-item label="" style="margin:0 0 0 10px;">
            <el-button type="primary" @click="getList">搜索</el-button>
        </el-form-item>
        <!-- <el-form-item label="" style="margin:0 0 0 20px;">
            <el-button @click="resetReq">重置</el-button>
        </el-form-item> -->
    </el-form>
    <el-table
            ref="multipleTable"
            :height="500"
            :data="tableData"
            @selection-change="changeSelect"
            stripe
            tooltip-effect="dark"
            style="width: 96%;text-align: center">
        <el-table-column align="center" type="selection" width="65 "> </el-table-column>

        <el-table-column label="提交时间"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.create_time }}</template>
        </el-table-column>

        <el-table-column label="单据编号"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.number }}</template>
        </el-table-column>

        <el-table-column label="客户名称"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.supplier_info?scope.row.supplier_info.name:'' }}</template>
        </el-table-column>
        <el-table-column label="采购实收金额（元）"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.payment_amount }}</template>
        </el-table-column>
        <el-table-column label="门店"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.out_warehouse_info&&scope.row.out_warehouse_info.shop_info?scope.row.warehouse_info.shop_info.name:''	 }}</template>
        </el-table-column>
        <el-table-column label="仓库"  min-width="65" align="center">
            <template slot-scope="scope" >{{ scope.row.warehouse_info?scope.row.warehouse_info.name:'' }}</template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
                <el-button type="text" size="small"  @click="remove(scope.row.id)">
                    删除
                </el-button>
                <el-button type="text" size="small"  @click="update(scope.row.id)">
                    编辑
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <!-- 页码 -->
    <div class="block" >
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="req.page"
                :page-size="req.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                class="flex-right">
        </el-pagination>
    </div>
    <div style="clear:both;"></div>
    <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;">
        <el-button  @click="exit();">取 消</el-button>
        <el-button type="primary" @click="save();">确 定</el-button>
    </div>
  </div>
</template>

<script>
import {shareList,shareDel } from "../../common/js/api";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        printId:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            tableData:[],
            selectList:[],
            total:0,
            currentPage:1,
            req:{
                limit:30,
                page:1,
                keywords:''
            }
        }
    },
    methods:{
        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        changeSelect(list){
            this.selectList = list;
        },
        getList(){
            this.req.id = this.printId;
            apis.purchaseList(this.req).then((res) => {
                this.tableData = res.data.data;
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        exit(){
            this.$emit('shareExit')
        },
        save(){
            this.$emit('saveSelect',this.selectList);
        },
        remove(id, key) {
            this.$confirm('确定删除此销售单?', '删除确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        update(tplId,){
            
        }
    },
    created(){
        this.getList();
    }
}
</script>
<style scoped lang="less">
@import url('../../../assets/less/normal');
.filexls {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}
</style>