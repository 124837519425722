<template>
  <div>
       <!-- 创建 -->
        <div style="width:100%;margin:0 auto;background:#fff;text-align:left;">
            <el-form ref="form" :inline="true" label-width="80px">
                <el-form-item label="商品分类">
                        <el-cascader
                        style="display:inline-block;"
                        v-model="req.classify_id"
                        size="small"
                        :options="source.goods_classify"
                        :show-all-levels="false"
                        @change="getList"
                        :props="{
                            expandTrigger: 'hover',
                            children:'child',
                            emitPath:false,
                            value:'id',
                            label:'name'
                        }"></el-cascader>
                </el-form-item>
                <el-form-item label="">
                    <el-input prefix-icon="el-icon-search" v-model="req.keywords" placeholder="请输入关键词搜索"></el-input>
                </el-form-item>
                <el-form-item label="" style="margin:0 0 0 50px;">
                    <el-button type="primary" @click="req.page=1;getList();">搜索</el-button>
                </el-form-item>
                <el-form-item label="" style="margin:0 0 0 20px;">
                    <el-button @click="resetReq">重置</el-button>
                </el-form-item>
            </el-form>

            <div class="p_container">
                <!-- 左边分类 -->
                <div class="left">
                    <el-tree
                    ref="tree1"
                    :data="source.goods_classify"
                    :render-content="renderContent"
                    node-key="id"
                    :highlight-current="true"
                    :default-expand-all="true"
                    :indent="20"
                    :props="defaultProps">
                    </el-tree>
                </div>
                <!-- 右边表格 -->
                <div class="right">
                    <el-table
                        @selection-change="getSelect"
                        ref="multipleTable"
                        :height="350"
                        :data="tableData"
                        stripe
                        tooltip-effect="dark"
                        style="width:100%;text-align: center">
                    <el-table-column align="center" type="selection" width="65 "> </el-table-column>
                    <el-table-column label="名称"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info?scope.row.goods_info.name:'' }}</template>
                    </el-table-column>
                    <el-table-column label="编号"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.specification_code?scope.row.specification_code:'' }}</template>
                    </el-table-column>
                    <el-table-column label="规格属性"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.disposeAll }}</template>
                    </el-table-column>

                    <el-table-column label="库存单位"  min-width="65" align="center">
                        <template slot-scope="scope">{{ scope.row.goods_info.unit_info?scope.row.goods_info.unit_info.name:'' }}</template>
                    </el-table-column>

                    <el-table-column label="采购价格"  min-width="55" align="center">
                        <template slot-scope="scope">
                            {{__tools.setPrice(scope.row.retail_price)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="库存"  min-width="55" align="center">
                        <template slot-scope="scope">
                            {{scope.row.inventory}}
                        </template>
                    </el-table-column>
                    </el-table>

                    <div class="_pageDiv" style="">
                        <el-pagination
                            style="margin:10px 0;"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="req.page"
                            :page-size="req.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                            class="flex-right">
                        </el-pagination>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer" style="text-align:center;height:60px;line-height:60px;margin-top:60px;">
                <el-button  @click="exit();">取 消</el-button>
                <el-button type="primary" @click="save();">确 定</el-button>
            </div>
        </div> 
  </div>
</template>

<script>
import * as dayjs from "dayjs";
import tools from "../../../extends/tools";
import apis from "../../common/js/api2";

export default {
    props:{
        storeId:{
            type:Number,
            default:0
        },
        lendId:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            dataInit:null,
            defaultProps:{
                children:'child',
                label: 'name'
            },
            source: {},
            //
            fileUrl: tools.baseFileUpload(),
            baseUrl: tools.baseURL(),
            saveDataInit:null,
            selectData:[],
            tableData: [],
            saveData:{
                name:'',
            },
            total:0,
            req:{
                limit:30,
                page:1,
                keywords:'',
                classify_id:'',
                warehouse_id:this.storeId,
                form_id:this.lendId
            }
        }
    },
    destroyed(){
    },
    methods:{
        getSelect(obj){
            this.selectData = obj;
        },
        copy(code,item){

        },
        beforeUpload(){
            this.uploadLoading = true;
        },
        save(){
            //把数据传递给父组件
            this.$emit('saveSelect',this.selectData);
            this.$emit('shareExit')
        },
        exit(){
            this.$emit('shareExit')
        },
        fleshList(){
            this.$emit('fleshList')
        },

        handleSizeChange(val) {
            this.req.limit = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.req.page = val;
            this.getList();
        },
        getList(){
            //
            apis.saleApplyGoods(this.req).then((res) => {
                this.tableData = res.data.data;
                for(let i=0;i<this.tableData.length;i++){
                    let item = this.tableData[i];
                    if(item.dispose_info){
                        item.disposeAll = item.dispose_info.join(',');
                    }
                }
                this.total = res.data.total;
            }).catch((err) => {
                this.$message('网络或程序错误，请稍候重试！', 'error');
            });
        },
        selectClassfy(obj){
            this.req.classify_id = obj.id;
            this.getList();
        },
        renderContent(h, { node, data, store }) {
            return (
            <span class="custom-tree-node" on-click={ () => this.selectClassfy(data) } style="display:inline-block;min-height:35px;line-height:35px;">
                <span>{node.label}</span>
            </span>);
        },
        resetReq(){
            this.req = tools.copy(this.reqInit);
        }
    },
    
    mounted(){
        //获取数据源
        apis.saleSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        this.getList();    
    }
}
</script>

<style scoped lang="less">
    @import url('../../../assets/less/normal');
    // 
    .p_container{
        width:100%;display: flex;
        height:350px;background:#fff;
        .left{
            width:200px;height:350px;background:#fff;overflow: auto;
        }
        .right{
            width:960px;
        }
    }
</style>